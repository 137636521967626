/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

'use client'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import axios from 'axios'
import { type PropsWithChildren, Suspense, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from 'styled-components'

import DeprecatedGmxApi from '~/backend/gmx-api/GmxApi'
import { TooltipProvider } from '~/components/Tooltip'
import { OperaDevTools } from '~/features/dev/OperaDevTools'
import { NoPermissions } from '~/features/permission/NoPermissions'
import { cn } from '~/utils/cn'

import { DisableInputNumberScroll } from './DisableInputNumberScroll'
import { getQueryClient } from './query-client'
import { styledColors } from './styled-palette'

const styledTheme = { colors: styledColors }

export default function Providers({ children }: PropsWithChildren) {
  const [queryClient] = useState(getQueryClient)

  return (
    <>
      <DisableInputNumberScroll />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={styledTheme}>
          <TooltipProvider delayDuration={0}>
            <Toaster position="top-center" toastOptions={{ className: '!max-w-xl' }} />
            <ConnectivityBanner />
            <NoPermissions />
            <Suspense fallback={null}>{children}</Suspense>
            <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
            <OperaDevTools />
          </TooltipProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  )
}

// TODO(duanei): Think there are much better things we can do here. In the react-query client,
// we can add toast that check the error responses instead of having this ugly banner.
// This was cloned from DC in the first commit. I.e., it was not even added to the backoffice by any
// member of the frontend team.
const ConnectivityBanner = () => {
  const [networkError, setNetworkError] = useState(false)

  useEffect(() => {
    const interceptors = [DeprecatedGmxApi].map((instance) => ({
      instance,
      interceptor: instance.interceptors.response.use(
        (response) => {
          setNetworkError(false)
          return response
        },
        (error: unknown) => {
          if (axios.isAxiosError(error) && error.message === 'Network Error') {
            setNetworkError(true)
          }
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          return Promise.reject(error)
        },
      ),
    }))
    return () =>
      interceptors.forEach(({ instance, interceptor }) =>
        instance.interceptors.response.eject(interceptor),
      )
  }, [])

  return (
    <div
      className={cn(
        'grid place-items-center overflow-hidden bg-error-120 px-6 transition-all ease-out',
        {
          'max-h-0 py-0': !networkError,
          'max-h-10 py-2': networkError,
        },
      )}
    >
      Cannot connect. Please check your internet connection.
    </div>
  )
}
