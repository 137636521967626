/*
 * Copyright © 2021 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

'use client'

import { BellIcon } from 'lucide-react'

import { ACCESS } from '~/access-control'
import { useAlerts } from '~/backend/gmx-api/alerts/Alerts.query'
import { useProfile } from '~/backend/gmx-api/profile/Profile.query'
import { isAdmin } from '~/backend/gmx-api/roles/utils'
import TypedLink from '~/components/typed-navigation/TypedLink'
import { ENV_HEADER_COLORS, HEADER_COLOR } from '~/config'

import Nav from '../components/Nav'

export default function Header() {
  return (
    <header
      className="flex items-center justify-between gap-8 px-6"
      style={{ backgroundColor: ENV_HEADER_COLORS ? HEADER_COLOR : '#4d4dff' }}
    >
      <TypedLink className="text-[#bdff00] hover:brightness-90" path="/" query={undefined}>
        <svg
          fill="none"
          height="47"
          viewBox="0 0 92 47"
          width="92"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_5903_86365)">
            <path
              clipRule="evenodd"
              d="M6.10352e-05 -9.91821e-05L3.13415 38.6569L89.9171 36.2808L91.994 2.19076L6.10352e-05 -9.91821e-05ZM29.7884 32.9275L5.5257 32.9105V4.3498L28.6227 4.76913L28.0815 13.5359L16.9615 12.9325L17.9921 21.7267H24.634L24.7111 19.7324L21.2154 19.2551V14.6575L30.3766 15.2257L29.7884 32.9275ZM61.427 16.0882L56.492 19.0426L61.427 21.7267V32.9173H49.556L46.5919 28.372L43.4831 32.9173H31.6134V21.7267L36.1397 18.6176L31.6134 16.0882V4.89752H43.4783L46.1905 9.32923L49.5512 4.89752H61.4222L61.427 16.0882ZM86.9614 32.9173L64.9697 32.9105H62.7144L63.3448 4.78844L85.8078 4.76913L85.3823 13.895L73.5222 13.2757L74.0586 21.571L86.9566 21.7278L86.9614 32.9173Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M7.69071 44.2353C7.83634 44.3103 7.96333 44.4138 8.06318 44.5387C8.15288 44.6539 8.22209 44.7821 8.26811 44.9182C8.31408 45.0625 8.33922 45.212 8.34284 45.3626C8.35225 45.5682 8.32035 45.7737 8.24882 45.9682C8.18063 46.1498 8.07214 46.3156 7.93058 46.4546C7.7735 46.6026 7.58379 46.7163 7.37489 46.7875C7.12306 46.875 6.8573 46.9215 6.58895 46.925L3.96837 47L3.7948 41.6263L6.03205 41.5626C6.31526 41.5507 6.59891 41.5737 6.87584 41.6308C7.09251 41.6733 7.29764 41.7572 7.47855 41.8774C7.62946 41.9822 7.75075 42.1203 7.83175 42.2796C7.91431 42.4503 7.95863 42.6353 7.96193 42.8228C7.96591 42.9353 7.95538 43.0479 7.93058 43.158C7.90618 43.2699 7.86562 43.3781 7.81004 43.4796C7.74447 43.5961 7.65517 43.6992 7.54726 43.783C7.42111 43.8819 7.28017 43.9627 7.12898 44.0228V44.0376C7.32532 44.0777 7.5145 44.1443 7.69071 44.2353ZM5.34014 43.7035L5.83798 43.6898C5.99752 43.6927 6.15397 43.6482 6.28519 43.5626C6.33764 43.526 6.37956 43.4776 6.40714 43.4218C6.43472 43.366 6.44708 43.3047 6.4431 43.2433C6.44328 43.1623 6.41766 43.0831 6.36957 43.016C6.31422 42.9475 6.23879 42.8957 6.15259 42.8671C6.03936 42.8277 5.91873 42.8107 5.7982 42.8171L5.31604 42.8319L5.34014 43.7035ZM6.58052 45.5648C6.6406 45.5228 6.68831 45.4671 6.71911 45.4029C6.74991 45.3386 6.76278 45.268 6.75651 45.1978C6.75633 45.1312 6.73942 45.0655 6.70715 45.0063C6.67489 44.947 6.62818 44.8958 6.57088 44.8569C6.41262 44.7639 6.2258 44.7239 6.04049 44.7432L5.3739 44.7626L5.40524 45.7296L6.07906 45.7103C6.25826 45.7125 6.43368 45.6616 6.58052 45.5648Z"
              fill="currentColor"
            />
            <path
              d="M11.1871 46.9329C12.6505 47.2137 13.906 46.277 14.1672 44.6084C14.4297 42.9322 13.531 41.5902 12.0676 41.3094C10.5549 41.0192 9.3356 42.0026 9.08041 43.6325C8.82521 45.2624 9.67441 46.6427 11.1871 46.9329ZM11.3467 45.9132C10.4462 45.7405 9.9516 44.826 10.1076 43.8296C10.2636 42.8331 11.0074 42.1563 11.9079 42.329C12.7663 42.4937 13.295 43.3749 13.133 44.41C12.9709 45.4451 12.2051 46.0779 11.3467 45.9132Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_5903_86365">
              <rect fill="white" height="47" width="92" />
            </clipPath>
          </defs>
        </svg>
      </TypedLink>

      <Nav className="h-16 grow" isTopLevel={true}>
        {[
          { href: `/games/all`, name: 'Games', roles: ACCESS.games },
          { href: `/challenges/all`, name: 'Challenges', roles: ACCESS.games },
          { href: `/mods/all`, name: 'Mods', roles: ACCESS.mods },
          { href: `/cms/tags/games`, name: 'CMS', roles: ACCESS.cms },
          { href: `/studios/all`, name: 'Studios', roles: ACCESS.studios },
          { href: `/offers/all`, name: 'Offers', roles: ACCESS.offers },
          { href: `/disputes/all`, name: 'Disputes', roles: ACCESS.disputes },
          { href: `/users/all`, name: 'Users', roles: ACCESS.users },
          { href: `/quests/all`, name: 'Quests', roles: ACCESS.quests },
          { href: `/stickers/all`, name: 'Stickers', roles: ACCESS.stickers },
          { href: `/settings/general`, name: 'Settings', roles: ACCESS.usersAdmin },
        ]}
      </Nav>
      <AlertsIndicator />

      <Profile />
    </header>
  )
}
const AlertsIndicator = () => {
  const availableRoles = useProfile({}).data?.roles ?? []
  const hasAccess = ACCESS.alerts.some((role) => availableRoles.includes(role))
  const { data } = useAlerts({}, { enabled: hasAccess })

  if (!hasAccess) {
    return null
  }

  const showIndicator = !!data?.alerts.some((alert) => alert.status === 'NEW')

  return (
    <TypedLink
      className="relative p-1 transition-all hover:scale-125 active:scale-100"
      path="/alerts"
      query={undefined}
    >
      <BellIcon />
      {showIndicator && (
        <div className="absolute right-0 top-0 size-1.5 rounded-full bg-primary-100"></div>
      )}
    </TypedLink>
  )
}

const Profile = () => {
  const profile = useProfile({})

  if (!profile.data) {
    return null
  }

  return (
    <div className="flex justify-center py-2">
      <div className="group relative">
        <div className="rounded bg-[#bdff00] px-4 py-2 text-sm text-neutral-0">
          {profile.data.email}
        </div>
        <div className="absolute right-0 top-full z-50 hidden min-w-full pt-1 animate-in fade-in slide-in-from-top-3 group-hover:block">
          <div className="flex flex-col gap-2 bg-neutral-20 p-4">
            <div className="flex items-center px-1 py-2 font-bold">{profile.data.name}</div>
            {isAdmin(profile.data) && (
              <>
                <hr className="border-neutral-32" />
                <TypedLink path="/bo-users" query={{}}>
                  BO Users
                </TypedLink>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
